import { useCallback, useEffect } from "react";
import useAlert from "hooks/useAlert";
import useConfirm from "hooks/useConfirm";
import ResponseError from "libs/ResponseError";

export default function useGlobalRejectHandler() {
  const { setAlertModal } = useAlert();
  const { setConfirmModal } = useConfirm();

  const commonErrorHandle = useCallback(
    (error: ResponseError) => {
      const { code } = error;
      switch (code) {
        case 1101:
          return setConfirmModal({
            content: `This service requires login.`,
            right: {
              label: "Confirm",
              event: () => {
                localStorage.removeItem("x-access-token");
                window.location.href = "/";
              },
            },
          });
        default:
          return setAlertModal({
            content: "Error in server - please contact customer center",
          });
      }
    },
    [setAlertModal, setConfirmModal]
  );

  return commonErrorHandle;
}
