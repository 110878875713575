import { Size, Weight } from "types/commonCSS";
import { FONT } from "styles/common";
import { BREAK_POINT } from "constants/breakpoint";

type ReturnType = { [key: string]: any };

export const conditionalStyling = ({
  condition,
  trueStyle,
  falseStyle,
}: {
  condition: boolean;
  trueStyle: ReturnType;
  falseStyle: ReturnType;
}) => {
  if (condition) {
    return trueStyle;
  }

  return falseStyle;
};

export const stylingText = ({
  color,
  size,
  weight,
}: {
  color?: string;
  size?: keyof Size & string;
  weight?: string & keyof Weight;
}): ReturnType => {
  const font: { [key: string]: string } = FONT;

  if (size && !weight && !color) {
    return { fontSize: font[size] };
  }

  if (!size && weight && !color) {
    return { fontWeight: font[weight] };
  }

  if (!size && !weight && color) {
    return { color };
  }

  if (!size && weight && color) {
    return { fontWeight: font[weight], color };
  }

  if (size && !weight && color) {
    return { fontSize: font[size], color };
  }

  if (size && weight && !color) {
    return { fontSize: font[size], fontWeight: font[weight] };
  }

  if (!size && !weight && !color) {
    console.error("stylingText 메서드에 하나 이상의 arguments를 입력해주세요.");

    return {
      color: "red",
      fontSize: font["72"],
      fontWeight: font.extraBold,
    };
  }

  return {
    color,
    fontSize: font[size ?? "16"],
    fontWeight: font[weight ?? "regular"],
  };
};

export const responsiveTest = (type: "mobile" | "tablet") => {
  switch (type) {
    case "mobile":
      return `@media (max-width: ${BREAK_POINT.mobileMax}px)`;
    case "tablet":
      return `@media (max-width: ${BREAK_POINT.tabletMax}px)`;
    default:
      return `@media (min-width: ${BREAK_POINT.pc}px)`;
  }
};

export const stylingResponsive = (
  type: "mobile" | "tablet",
  styledObj: ReturnType
) => {
  return {
    [responsiveTest(type)]: styledObj,
  };
};
