import dayjs, { ConfigType } from "dayjs";
import forEach from "lodash/forEach";
import reduce from "lodash/reduce";
import find from "lodash/find";
import { GENDER_LABEL } from "components/organisms/Quantity/structure";
import { extensionIcons } from "assets/Icons";
import { COLOR } from "styles/common";
import { BodyContentType, ParameterType } from "types/api";

export function createSearchParams(obj: ParameterType = {}, base?: string) {
  const result = reduce(
    obj,
    (searchParams, value, key) => {
      if (value instanceof Array) {
        searchParams.delete(key);
        forEach(value, (item) => searchParams.append(key, item.toString()));
      } else if (value !== undefined && value !== "") {
        searchParams.set(key, value.toString());
      } else {
        searchParams.delete(key);
      }

      return searchParams;
    },
    new URLSearchParams(base)
  ).toString();

  return result === "" ? "" : `?${result}`;
}

export function createFormData(obj: BodyContentType = {}) {
  return reduce(
    obj,
    (form, value, key) => {
      if (value instanceof File) {
        form.append(key, value);
        return form;
      }

      if (value instanceof Array) {
        const isFile = !!find(
          value,
          (item: string | number | File) => item instanceof File
        );

        if (isFile) {
          forEach(
            value,
            (item) => item instanceof File && form.append(key, item)
          );
          return form;
        }
      }

      form.append(key, JSON.stringify(value));
      return form;
    },
    new FormData()
  );
}

export const handleSignOut = () => {
  localStorage.removeItem("x-access-token");
  window.location.reload();
};

export const deleteProtocol = (url: string) => {
  const result = url.replace(/^(http(s)?:\/\/)/gi, "");
  return result;
};

export const extract = {
  keys: <T extends Object>(obj: T): Array<keyof T> => {
    return Object.keys(obj) as Array<keyof T>;
  },

  index: (structure: { [key: string]: any }, target: string) => {
    return structure.findIndex(({ id }: { id: string }) => target === id);
  },
};

export const checkValid = {
  email: (email: string) => {
    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return !!reg.test(email);
  },

  phone: (number: string) => {
    let removeHyphen = number?.replace(/-/g, "");
    const regPhone = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/;
    return !!regPhone.test(removeHyphen);
  },

  password: (pw: string) => {
    const reg =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,20}$/;
    return !!reg.test(pw);
  },

  emphasis: (value: string) => {
    const checkValueList = [
      "sample_published",
      "sample_production_write",
      "quotation_published",
      "payment_1st_request",
      "payment_2nd_request",
    ];

    return checkValueList.some((item) => {
      return item === value;
    });
  },

  sizeLimit: (fileList: File[], limit: number) => {
    const limitSize = 1024 * 1024 * limit;
    const totalSize = fileList.reduce((acc, cur) => {
      return (acc += cur.size);
    }, 0);
    return totalSize > limitSize;
  },

  duplicate: (file: File[], uploadedFile: File[] | null) => {
    const selectedFile = file.map((file) => file.name);
    const includedFile = uploadedFile?.map((file) => file.name);
    return (
      selectedFile.filter((name) => includedFile?.includes(name)).length > 0
    );
  },
};

export const convert = {
  urlToFile: async (name: string, url: string) => {
    const response = await fetch(url);
    const data = await response.blob();
    const ext = url.split(".").pop(); // url 구조에 맞게 수정할 것
    // const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
    const metadata = { type: `image/${ext}` };
    return new File([data], name, metadata);
  },

  genderToKey: (gender: string) => {
    return extract.keys(GENDER_LABEL)[
      Object.values(GENDER_LABEL).findIndex((value) => value === gender)
    ];
  },

  dateFormat: (date: ConfigType, format: string = "MM.DD.YYYY") => {
    if (!date) return "";
    return dayjs(date).format(format);
  },

  toExtensionIcon: (fileName: string) => {
    const { png, jpg, gif, pdf } = extensionIcons;
    const extension = fileName.split(".")[1];

    switch (extension) {
      case "jpeg":
      case "jpg":
        return jpg;
      case "gif":
        return gif;
      case "png":
      case "PNG":
        return png;
      default:
        return pdf;
    }
  },

  toColor: {
    payment: (status: string) => {
      switch (status) {
        case "paid":
          return COLOR.blue.medium;
        case "unpaid":
          return COLOR.red.medium;
        case "wait":
          return COLOR.gray.extraDark;
        default:
          return;
      }
    },

    negotiation: (status: string) => {
      switch (status) {
        case "In progress":
          return COLOR.green.medium;
        case "Stand by":
          return COLOR.gray.extraDark;
        case "Complete":
          return COLOR.blue.medium;
        default:
          return COLOR.gray.extraDark;
      }
    },

    status: (status: string) => {
      switch (status) {
        case "production_request_complete":
        case "project_complete":
          return COLOR.blue.medium;
        case "discussion_cancel":
        case "quotation_cancel":
        case "quotation_expired":
        case "project_cancel":
          return COLOR.red.medium;
        case "sample_expired":
        case "sample_production_cancel":
          return COLOR.orange.dark;
        case "sample_request":
        case "sample_write":
        case "sample_published":
        case "sample_production_write":
        case "sample_production_published":
        case "discussion":
        case "quotation_write":
        case "quotation_published":
        case "payment_2nd_request":
        case "producting":
        case "payment_1st_request":
        case "payment_wait":
          return COLOR.green.medium;
        default:
          return;
      }
    },
  },

  toIndex: (status: string) => {
    switch (status) {
      case "project_cancel":
        return 0;

      case "sample_request":
      case "sample_write":
      case "payment_wait":
      case "payment_1st_request":
      case "producting":
        return 1;

      case "sample_published":
      case "sample_expired":
      case "payment_2nd_request":
        return 2;

      case "sample_production_write":
      case "sample_production_published":
      case "sample_production_cancel":
        return 3;

      case "discussion":
      case "discussion_cancel":
      case "quotation_write":
      case "project_complete":
        return 4;

      case "quotation_published":
      case "quotation_expired":
      case "quotation_cancel":
      case "production_request_complete":
        return 5;

      default:
        console.error("unexpected progress status");
        return 9999;
    }
  },
};
