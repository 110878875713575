import { Size, Weight } from "types/commonCSS";
import { stylingText } from "libs/styling";

export const COLOR = {
  main: "#3E5EE0",

  white: "#fff",

  blue: {
    extraLight: "#DEF3FF",
    light: "#00A3FF",
    medium: "#2182EA",
    dark: "#13254B",
  },

  red: {
    extraLight: "#FFF6F6",
    medium: "#FF4343",
  },

  orange: {
    medium: "#FFB84D",
    dark: "#E89D2C",
  },

  green: {
    medium: "#5BC57D",
    dark: "#169551",
  },

  gray: {
    light: "#f6f6f6",
    medium: "#cfcfcf",
    dark: "#9b9b9b",
    extraLight: "#fcfcfc",
    extraDark: "#2c2c2c",
  },
};

export const FONT: Size & Weight = {
  "10": "0.625rem",
  "12": "0.75rem",
  "14": "0.875rem",
  "16": "1rem",
  "20": "1.25rem",
  "24": "1.5rem",
  "28": "1.75rem",
  "30": "1.875rem",
  "32": "2rem",
  "36": "2.25rem",
  "48": "3rem",
  "72": "4.5rem",

  regular: "400",
  bold: "700",
  extraBold: "800",
};

export const DISABLED = {
  cursor: "not-allowed",
  color: COLOR.gray.medium,
};

export const BUTTONLAYOUT = {
  width: "100%",
  ...stylingText({ weight: "bold" }),
};
