import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import useResponsive from "hooks/useResponsive";
import { estimate } from "pages/Estimate/page";
import { manufacturing } from "pages/Manufacturing/page";
import RestrictRoute, { isSignIn } from "components/layout/RestrictRoute";
import Header from "components/organisms/Header";

const Modal = React.lazy(() => import("Modal"));
const BaseLayout = React.lazy(() => import("components/layout/BaseLayout"));
const Main = React.lazy(() => import("pages/Home/page"));
const SignUp = React.lazy(() => import("pages/SignUp/page"));
const SignIn = React.lazy(() => import("pages/SignIn/page"));
const MyInfo = React.lazy(() => import("pages/MyInfo/page"));
const FindAccount = React.lazy(() => import("pages/Account/page"));
const VirtualEstimate = React.lazy(() => import("pages/VirtualEstimate/page"));
const Estimate = React.lazy(() => import("pages/Estimate/page"));
const Manufacturing = React.lazy(() => import("pages/Manufacturing/page"));
const NavigationBar = React.lazy(
  () => import("components/layout/NavigationBar")
);

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route
        path="/*"
        element={<RestrictRoute allow={!isSignIn} redirect="/" />}
      >
        <Route path="*" element={<BaseLayout type="auth" />}>
          <Route path="auth/signin" element={<SignIn />} />
          <Route path="auth/signup" element={<SignUp />} />
          <Route path="auth/:tabId" element={<FindAccount />} />
          <Route path="*" element={<Navigate to="/auth/signin" />} />
        </Route>
      </Route>
      <Route
        path="/*"
        element={<RestrictRoute allow={isSignIn} redirect="/auth/signin" />}
      >
        <Route path="*" element={<BaseLayout type="normal" />}>
          <Route path="myInfo" element={<MyInfo />} />
          <Route path="virtualEstimate" element={<VirtualEstimate />} />
          <Route
            path="estimate/detail/:projectId"
            element={<Estimate component={estimate.Detail} />}
          />
          <Route
            path="estimate"
            element={<Estimate component={estimate.List} />}
          />
          <Route
            path="production/detail/:categoryId/:projectId"
            element={<Manufacturing component={manufacturing.Detail} />}
          />
          <Route
            path="production"
            element={<Manufacturing component={manufacturing.List} />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Route>
    </Routes>
  );
};

const Root = () => {
  const { isTablet } = useResponsive();

  return (
    <>
      <Header />
      <Router />
      {isTablet && isSignIn && <NavigationBar />}
      <Modal />
    </>
  );
};

export const router = createBrowserRouter(
  createRoutesFromElements(<Route path="*" element={<Root />} />)
);
