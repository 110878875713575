import { useEffect } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";
import {
  contentModalAtom,
  ContentModalType,
} from "recoil/modal/contentModalAtom";

export default function useContentModal() {
  const handleReset = useResetRecoilState(contentModalAtom);
  const [{ isContentModalOpen, title, content }, handleModal] =
    useRecoilState(contentModalAtom);

  const setContentModal = ({
    content,
    title,
  }: Omit<ContentModalType, "isContentModalOpen">) => {
    return handleModal(() => ({
      isContentModalOpen: true,
      title: title || null,
      content,
    }));
  };

  const handleContentModalOff = () => {
    return handleReset();
  };

  useEffect(() => {
    if (!isContentModalOpen) return;
    return () => handleReset();
  }, [isContentModalOpen]);

  return {
    isContentModalOpen,
    title,
    content,
    setContentModal,
    handleContentModalOff,
  };
}
