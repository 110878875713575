/** @jsxImportSource @emotion/react */
import React from "react";
import useUserInfo from "hooks/useUserInfo";
import useResponsive from "hooks/useResponsive";
import useContentModal from "hooks/useContentModal";
import useHandleHistory from "hooks/useHandleHistory";
import { isSignIn } from "components/layout/RestrictRoute";
import useHeader from "components/organisms/Header/useHeader";
import Button from "components/atoms/Button";
import { stylingResponsive, stylingText } from "libs/styling";
import { COLOR } from "styles/common";

const PopupMenu = React.lazy(
  () => import("components/organisms/Header/molecures/PopupMenu")
);
const Menu = React.lazy(
  () => import("components/organisms/Header/molecures/Menu")
);

export default function Header() {
  const userInfo = useUserInfo();
  const { isPc } = useResponsive();
  const { isContentModalOpen } = useContentModal();
  const { pathname } = useHandleHistory();
  const {
    isScroll,
    isOpenedPopUp,
    handlePage,
    handlePopUp,
    handleClosePopUp,
  } = useHeader();
  const isSignInWithPc = isSignIn && isPc;

  return (
    <div css={layout({ isScroll, path: pathname, isOpen: isContentModalOpen })}>
      <div css={wrapper}>
        <div css={left}>
          <p css={styledLogo} onClick={() => handlePage("/")}>
            SINPLE
          </p>
          {isSignInWithPc && <Menu handlePage={handlePage} />}
        </div>
        <div css={right} onBlur={handleClosePopUp} tabIndex={0}>
          {isSignInWithPc && (
            <PopupMenu
              userInfo={userInfo}
              isOpenedPopUp={isOpenedPopUp}
              handlePopUp={handlePopUp}
              handlePage={handlePage}
            />
          )}
          {isSignIn ? (
            isPc && (
              <Button.Button
                css={requestButton}
                customStyle="default"
                onClick={() => handlePage("/virtualEstimate")}
              >
                Request a quotation
              </Button.Button>
            )
          ) : (
            <Button.Button
              css={loginButton}
              customStyle="default"
              onClick={() => handlePage(`/auth/signin`)}
            >
              Log in
            </Button.Button>
          )}
        </div>
      </div>
    </div>
  );
}

const layout = ({
  isScroll,
  path,
  isOpen,
}: {
  isScroll: boolean;
  path: string;
  isOpen: boolean;
}) => ({
  position: "fixed" as const,
  zIndex: "100",
  width: "calc(100vw - 16px)",
  borderWidth: 0,
  borderRadius: 0,
  boxShadow:
    isScroll || path !== "/" ? "0px 0px 2px rgba(79, 79, 79, 0.5)" : "none",
  ...stylingResponsive("tablet", {
    width: "100vw",
  }),
  backgroundColor: `${
    isScroll || path !== "/"
      ? COLOR.white
      : isOpen
      ? COLOR.white
      : "transparent"
  }`,
});

const wrapper = {
  display: "flex",
  justifyContent: "space-between",
  maxWidth: "1320px",
  height: "100px",
  margin: "0 auto",
  padding: "0 20px",
  ...stylingResponsive("tablet", { height: "60px", padding: "0 16px" }),
};

const left = {
  display: "flex",
  alignItems: "center",
};

const styledLogo = {
  cursor: "pointer",
  ...stylingText({
    size: "30",
    weight: "extraBold",
    color: COLOR.main,
  }),

  ...stylingResponsive("tablet", {
    ...stylingText({ size: "24" }),
  }),
};

const right = {
  position: "relative" as const,
  display: "flex",
  alignItems: "center",
};

const requestButton = {
  width: "184px",
  height: "48px",
  borderRadius: "38px",
  padding: "0px",
};

const loginButton = {
  width: "156px",
  height: "48px",
  borderRadius: "38px",
  ...stylingResponsive("tablet", {
    width: "107px",
    height: "32px",
    borderRadius: "25px",
    padding: "0px",
    ...stylingText({ size: "12" }),
  }),
};
