/** @jsxImportSource @emotion/react */
import React, { ForwardedRef, forwardRef } from "react";
import { BaseTypes, ButtonTypes } from "types/common";
import { COLOR, DISABLED, BUTTONLAYOUT } from "styles/common";

const Button = {
  Wrapper: ({ children, ...props }: BaseTypes) => {
    return <div {...props}>{children}</div>;
  },

  Button: forwardRef(
    (
      {
        type = "button",
        children,
        customStyle,
        onClick,
        ...props
      }: ButtonTypes,
      ref: ForwardedRef<HTMLButtonElement>
    ) => {
      const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onClick?.(e);
      };
      return (
        <button
          css={styledButton(customStyle)}
          ref={ref}
          type={type}
          onClick={handleClick}
          {...props}
        >
          {children}
        </button>
      );
    }
  ),
};

export default Button;

const MAINCOLOR = COLOR.main;
const DISABLEDSTYLE = {
  ...DISABLED,
  border: `1px solid ${COLOR.gray.medium}`,
  backgroundColor: "transparent",
};

const textButtonStyle = {
  ...BUTTONLAYOUT,

  "&:hover": {
    color: MAINCOLOR,
  },

  "&:disabled": {
    ...DISABLED,
  },
};

const outlineButtonStyle = {
  ...BUTTONLAYOUT,
  padding: "0.5rem 1rem",
  color: MAINCOLOR,
  backgroundColor: "transparent",
  border: `1px solid ${MAINCOLOR}`,
  borderRadius: "5px",

  "&:disabled": {
    ...DISABLEDSTYLE,
  },
};

const defaultButtonStyle = {
  ...BUTTONLAYOUT,
  padding: "0.5rem 1rem",
  color: COLOR.white,
  backgroundColor: MAINCOLOR,
  border: `1px solid ${MAINCOLOR}`,
  borderRadius: "5px",

  "&:disabled": {
    ...DISABLEDSTYLE,
  },
};

const normal = {
  ...outlineButtonStyle,
  color: COLOR.gray.extraDark,
  borderColor: COLOR.gray.extraDark,
};

const logout = {
  ...outlineButtonStyle,
  color: COLOR.red.medium,
  borderColor: COLOR.gray.medium,
};

const styledButton = (type?: string) => {
  const convertType = type?.toLowerCase();

  switch (convertType) {
    case "normal":
      return normal;
    case "logout":
      return logout;
    case "text":
      return textButtonStyle;
    case "outline":
      return outlineButtonStyle;
    default:
      return defaultButtonStyle;
  }
};
