import { atom } from "recoil";

export type AlertModalType = {
  content: string;
};

export const alertModalInitialState = {
  content: "",
};

export const alertModalAtom = atom<AlertModalType>({
  key: "alertModalAtom",
  default: alertModalInitialState,
});
