import { getTotalEstimateList } from "pages/Estimate/apis";
import React from "react";

const StatusList = React.lazy(() => import("components/organisms/StatusList"));
const EstimateTemplate = React.lazy(
  () => import("pages/Estimate/template/Default")
);

export default function Estimate({
  component: Component,
}: {
  component: () => JSX.Element;
}) {
  return <Component />;
}

const List = () => {
  return (
    <StatusList
      api={getTotalEstimateList}
      title="Quotation status"
      path="/estimate/detail"
    />
  );
};

const Detail = () => <EstimateTemplate />;

export const estimate = { List, Detail };
