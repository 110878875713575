import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { confirmModalAtom } from "recoil/modal/confirmModalAtom";

export default function useConfirm() {
  const [{ content }, handleModal] = useRecoilState(confirmModalAtom);

  const handleClose = () => {
    handleModal({
      content: "",
      left: {
        disable: false,
        label: "",
        event: () => {},
      },
      right: {
        disable: false,
        label: "",
        event: () => {},
      },
    });
  };

  const handeLeftButtonEvent = (event?: () => void) => () => {
    event?.();
    handleClose();
  };

  const handeRightButtonEvent = (event: () => void) => () => {
    event?.();
    handleClose();
  };

  const setConfirmModal = ({
    content: text,
    left,
    right,
  }: {
    content: string;
    left?: { disable?: boolean; label?: string; event?: () => void };
    right: { disable?: boolean; label: string; event: () => void };
  }) => {
    handleModal({
      content: text,
      left: { ...left, event: handeLeftButtonEvent(left?.event) },
      right: { ...right, event: handeRightButtonEvent(right.event) },
    });
  };

  useEffect(() => {
    if (!content) return;
    const block = () =>
      window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", block);

    return () => window.removeEventListener("popstate", block);
  }, []);

  return { setConfirmModal };
}
