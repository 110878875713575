import { useCallback, useEffect, useState } from "react";
import useHandleHistory from "hooks/useHandleHistory";

export default function useHeader() {
  const { pathname, handleHistoryPush } = useHandleHistory();
  const [isScroll, setScroll] = useState(false);
  const [isOpenedPopUp, setPopUp] = useState(false);

  const handlePopUp = useCallback(() => {
    return setPopUp((prev) => !prev);
  }, []);

  const handleClosePopUp = useCallback(() => {
    setPopUp(false);
  }, []);

  const handlePage = useCallback(
    (path: string) => {
      handleClosePopUp();

      return pathname === path
        ? window.location.reload()
        : handleHistoryPush(path);
    },
    [pathname]
  );

  useEffect(() => {
    const handleScroll = () => {
      setScroll((prev) =>
        prev === !!window.pageYOffset ? prev : !!window.pageYOffset
      );
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return {
    isScroll,
    isOpenedPopUp,
    handlePage,
    handlePopUp,
    handleClosePopUp,
  };
}
