export interface ApiErrorResponse {
  code: number;
  status: number;
  message: string;
}

class ResponseError {
  readonly code: ApiErrorResponse["code"];
  readonly status: ApiErrorResponse["status"];
  readonly message: ApiErrorResponse["message"];
  readonly defaultText: string;

  constructor(error: any) {
    const { code, status, message } = error;
    this.code = code;
    this.status = status;
    this.message = message;
    this.defaultText = `Error Code ${code}: ${message}`;
  }
}

export default ResponseError;
