import profile from "assets/icons/profile.png";
import dropZone from "assets/icons/dropzone.png";
import arrowCarousel from "assets/icons/arrow_main_carousel.png";

import imageUpload from "assets/icons/image_upload.png";
import imageDelete from "assets/icons/image_delete.png";

import noEstimateStatus from "assets/icons/noEstimateStatus.png";
import noSearchResults from "assets/icons/noSearchResults.png";
import noProductionStatus from "assets/icons/noProductionStatus.png";

import historyPng from "assets/icons/history.png";
import historyActivePng from "assets/icons/history_active.png";

import png from "assets/icons/extension_png.svg";
import gif from "assets/icons/extension_gif.svg";
import jpg from "assets/icons/extension_jpg.svg";
import pdf from "assets/icons/extension_pdf.svg";

import home from "assets/icons/nav_home.svg";
import homeActive from "assets/icons/nav_home_active.svg";
import estimate from "assets/icons/nav_estimate.svg";
import estimateActive from "assets/icons/nav_estimate_active.svg";
import production from "assets/icons/nav_manufacturing_status.svg";
import productionActive from "assets/icons/nav_manufacturing_status_active.svg";
import myinfo from "assets/icons/nav_myinfo.svg";
import myinfoActive from "assets/icons/nav_myinfo_active.svg";

import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { ReactComponent as Checked } from "assets/icons/checked.svg";
import { ReactComponent as Question } from "assets/icons/question.svg";
import { ReactComponent as Instagram } from "assets/icons/instagram.svg";
import { ReactComponent as RemoveCircle } from "assets/icons/remove.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search_icon.svg";
import { ReactComponent as ExclamationMark } from "assets/icons/exclamation_mark.svg";
import { ReactComponent as QuestionMark } from "assets/icons/question_mark.svg";

export const SVGComponent = {
  Arrow,
  Close,
  Checked,
  Question,
  RemoveCircle,
  SearchIcon,
  Instagram,
  ExclamationMark,
  QuestionMark,
};

export const extensionIcons = {
  png,
  gif,
  jpg,
  pdf,
};

export const bottomNav: { [key: string]: string } = {
  home,
  homeActive,
  estimate,
  estimateActive,
  production,
  productionActive,
  myinfo,
  myinfoActive,
};

export const icon = {
  arrowCarousel,
  profile,
  noEstimateStatus,
  noSearchResults,
  noProductionStatus,
  bottomNav,
  imageUpload,
  imageDelete,
  dropZone,
  historyPng,
  historyActivePng,
};
