export const GENDER_LABEL = {
  commons: "Unisex",
  female: "Female",
  male: "Male",
};

export const SIZE_STRUCTURE = {
  commons: ["US5", "US6", "US7", "US8", "US9", "US10"],
  female: ["US6", "US7", "US8"],
  male: ["US7", "US8", "US9", "US10"],
};
