const queryKeys = {
  USERINFO: "userInfo",
  ESTIMATE_DETAIL_TAB: "estimateDetailTab",
  REQUEST_DOCUMENT: "requestDocument",
  ESTIMATE: "estimate",
  SAMPLE: "sample",
  NEGOTIATION: "negotiation",
  QUOTATION: "quotation",
  MYINFO: "profileList",
  CATEGORY_SHOESCATEGORY: "shoesCategory",
  ESTIMATE_SHOESSIZE: "shoesSize",
  STATUS_LIST: "statusList",
  LIST_CONTRACTSTATUSTAB: "contractStatusTab",
  MANUFACTURING_PAYMENTSTATUSTAB: "paymentStatusTab",
  MANUFACTURING_PAYMENTDATA: "paymentData",
  MANUFACTURING_WORKHISTORY: "workHistory",
  MANUFACTURING_PRODUCTIONSTATUSTAB: "productionStatusTab",
  MANUFACTURING_REVISIONESTIMATEDATA: "revisionEstimateData",
};

export default queryKeys;
