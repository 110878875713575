import { SampleParameterType } from "pages/Estimate/types";
import createAPI from "components/instanceApi";
import { RequestFunction } from "types/api";

export const getTotalEstimateList: RequestFunction<{
  page: number;
  tab: string;
  keyword: string;
  align: string;
}> = ({ page, tab, keyword, align }) => {
  return createAPI({
    method: `get`,
    endpoint: `/estimates`,
    params: {
      page,
      filter_bar_status: tab,
      filter_search_text: keyword,
      align_type: align,
    },
  });
};

export const getEstimate: RequestFunction<{
  estimateId: string;
}> = ({ estimateId }) => {
  return createAPI({
    method: `get`,
    endpoint: `/estimates/${estimateId}/sample-estimate`,
  });
};

export const getEstimateStatus: RequestFunction<{ estimateId: string }> = ({
  estimateId,
}) => {
  return createAPI({
    method: `get`,
    endpoint: `/estimates/${estimateId}/tab`,
  });
};

export const getNegotiationResult: RequestFunction<{
  estimateId: string;
}> = ({ estimateId }) => {
  return createAPI({
    method: `get`,
    endpoint: `/estimates/${estimateId}/discussion`,
  });
};

export const getQuotationEstimate: RequestFunction<{
  estimateId: string;
}> = ({ estimateId }) => {
  return createAPI({
    method: `get`,
    endpoint: `/estimates/${estimateId}/quotation-estimate`,
  });
};

export const getRequestInfoOfSample: RequestFunction<{
  estimateId: string;
}> = ({ estimateId }) => {
  return createAPI({
    method: `get`,
    endpoint: `/estimates/${estimateId}/sample-production`,
  });
};

export const getRequestInfoOfEstimate: RequestFunction<{
  estimateId: string;
}> = ({ estimateId }) => {
  return createAPI({
    method: `get`,
    endpoint: `/estimates/${estimateId}/sample`,
  });
};

export const postConfirmEstimateAndQuotation: RequestFunction<{
  id: string;
  latestStatus: string;
}> = ({ id, latestStatus }) => {
  return createAPI({
    method: `post`,
    endpoint: `/estimate/update-status`,
    body: {
      id,
      latest_status: latestStatus,
    },
  });
};

export const patchSaveAsDrafts: RequestFunction<
  Omit<SampleParameterType, "isTemporarySaved"> & {
    id: string;
    isChecked: boolean;
  }
> = (params) => {
  const { id, attachment, size, note, isChecked } = params;

  const estimate_data = {
    content: note || null,
    prototype_agree: isChecked,
  };

  return createAPI({
    method: `patch`,
    endpoint: `/estimates/draft-sample-production`,
    body: {
      id,
      estimate_data,
      size: size.quantity,
      gender: size.gender,
      attachment,
    },
  });
};

export const postRequestForSample: RequestFunction<
  Omit<SampleParameterType, "isTemporarySaved"> & {
    id: string;
    isChecked: boolean;
  }
> = (params) => {
  const { id, attachment, size, note, isChecked } = params;

  return createAPI({
    method: `post`,
    endpoint: `/estimates/sample-production`,
    body: {
      id,
      estimate_data: {
        content: note,
        prototype_agree: isChecked,
      },
      size: size.quantity,
      gender: size.gender,
      attachment,
    },
  });
};
