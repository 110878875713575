import createAPI from "components/instanceApi";
import { RequestFunction } from "types/api";

export const getProductionInfoOfTotalQuotation: RequestFunction<{
  page: number;
  tab: string;
  keyword: string;
  align: string;
}> = ({ page, tab, keyword, align }) => {
  return createAPI({
    method: `get`,
    endpoint: `/productions`,
    params: {
      page,
      // limit,
      filter_bar_status: tab,
      filter_search_text: keyword,
      align_type: align,
    },
  });
};

export const getManufacturingTab: RequestFunction<{
  quotationId: string;
}> = ({ quotationId }) => {
  return createAPI({
    method: `get`,
    endpoint: `/productions/${quotationId}/tab`,
  });
};

export const getPayment: RequestFunction<{ quotationId: string }> = ({
  quotationId,
}) => {
  return createAPI({
    method: `get`,
    endpoint: `/productions/${quotationId}/payment`,
  });
};

export const getProgressWorkHistory: RequestFunction<{
  quotationId: string;
}> = ({ quotationId }) => {
  return createAPI({
    method: `get`,
    endpoint: `/productions/${quotationId}/manufacturing`,
  });
};

export const getRevisionEstimateList: RequestFunction<{
  quotationId: string;
}> = ({ quotationId }) => {
  return createAPI({
    method: `get`,
    endpoint: `/productions/${quotationId}/revisions`,
  });
};
