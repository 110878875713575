import { useLocation, useNavigate } from "react-router-dom";

export default function useHandleHistory() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const handleHistoryPush = (path: string) => {
    return navigate(path);
  };

  return { location, pathname, handleHistoryPush };
}
