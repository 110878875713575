import { atom } from "recoil";

export type ContentModalType = {
  isContentModalOpen: boolean;
  title?: (() => JSX.Element) | null;
  content: (() => JSX.Element | null) | null;
};

export const contentModalState = {
  isContentModalOpen: false,
  title: null,
  content: null,
};

export const contentModalAtom = atom<ContentModalType>({
  key: "contentModalAtom",
  default: contentModalState,
});
