import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import useGlobalRejectHandler from "hooks/useGlobalErrorHandler";
import { router } from "pages/Root";
import ResponseError from "libs/ResponseError";

export default function App() {
  const commonErrorHandle = useGlobalRejectHandler();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 0,
      },
    },
    queryCache: new QueryCache({
      onError: (e) => {
        if (e instanceof ResponseError) commonErrorHandle(e);
      },
    }),
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={null}>
        <RouterProvider router={router} />
      </Suspense>
    </QueryClientProvider>
  );
}
