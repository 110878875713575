import { atom } from "recoil";

export type ConfirmModalType = {
  content: string;
  left?: {
    disable?: boolean;
    label?: string;
    event?: (e?: React.MouseEvent) => void;
  };
  right: {
    disable?: boolean;
    label: string;
    event: (e?: React.MouseEvent) => void;
  };
};

const confirmModalInitialState = {
  content: "",
  left: {
    disable: false,
    label: "",
    event: () => {},
  },
  right: {
    disable: false,
    label: "",
    event: () => {},
  },
};

export const confirmModalAtom = atom<ConfirmModalType>({
  key: "confirmModalAtom",
  default: confirmModalInitialState,
});
