import React from "react";
import { useParams } from "react-router-dom";
import { getProductionInfoOfTotalQuotation } from "pages/Manufacturing/apis";

const ManufacturingTemplate = React.lazy(
  () => import("pages/Manufacturing/template/Default")
);
const StatusList = React.lazy(() => import("components/organisms/StatusList"));
const Payment = React.lazy(() => import("pages/Manufacturing/page/Payment"));
const Revision = React.lazy(() => import("pages/Manufacturing/page/Revision"));
const Production = React.lazy(
  () => import("pages/Manufacturing/page/Production")
);

export default function Manufacturing({
  component: Component,
}: {
  component: () => JSX.Element;
}) {
  return <Component />;
}

const List = () => (
  <StatusList
    api={getProductionInfoOfTotalQuotation}
    title="Production status"
    path="/production/detail/payment"
  />
);

const Detail = () => {
  const { categoryId } = useParams() as { categoryId: string };

  const Component = ({ categoryId }: { categoryId: string }) => {
    switch (categoryId) {
      case "payment":
        return <Payment />;
      case "production":
        return <Production />;
      case "revision":
        return <Revision />;
      default:
        console.error("unexpected categoryId in manufacturing Tab");
        return null;
    }
  };

  return (
    <ManufacturingTemplate>
      <Component categoryId={categoryId} />
    </ManufacturingTemplate>
  );
};

export const manufacturing = { List, Detail };
