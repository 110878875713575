import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import App from "App";
import "styles/reset.css";
import "styles/common.css";

ReactDOM.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>,
  document.getElementById("root")
);
