import { useCallback, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { alertModalAtom } from "recoil/modal/alertModalAtom";

export default function useAlert() {
  const handleModal = useSetRecoilState(alertModalAtom);

  const setAlertModal = useCallback(({ content }: { content: string }) => {
    handleModal({
      content,
    });
  }, []);

  useEffect(() => {
    return () =>
      handleModal({
        content: "",
      });
  }, []);

  return { setAlertModal };
}
