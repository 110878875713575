import { useMemo } from "react";
import { useQuery } from "react-query";
import { getUserInfo } from "components/organisms/Header/apis";
import { isSignIn } from "components/layout/RestrictRoute";
import queryKeys from "constants/queryKeys";

export default function useUserInfo() {
  const { data } = useQuery({
    queryKey: queryKeys.USERINFO,
    queryFn: getUserInfo,
    staleTime: Infinity,
    enabled: isSignIn,
  });

  const userInfo = useMemo(() => {
    return {
      userName: data?.username,
      phone: data?.phone,
      companyName: data?.company_name,
    };
  }, [data]);

  return userInfo;
}
