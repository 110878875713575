import axios from "axios";
import { BASE_URL } from "constants/key";
import { Instance } from "types/api";
import ResponseError from "libs/ResponseError";
import { createFormData, createSearchParams } from "libs/utils";

const instance = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  config.headers.Authorization = localStorage.getItem("x-access-token")
    ? `Bearer ${localStorage.getItem("x-access-token")}`
    : "";

  return config;
});

instance.interceptors.response.use(undefined, (error) => {
  const { response } = error;
  if (response.status === 500) {
    return Promise.reject({
      status: 500,
      code: 99999,
      message: "Network Error",
    });
  }
  return Promise.reject({ ...response.data, status: response.status });
});

const createAPI: Instance = async ({ method, endpoint, params, body }) => {
  try {
    const { data, status } = await instance({
      method,
      url: `${endpoint}${createSearchParams(params)}`,
      data: createFormData(body),
    });

    if (status !== 200) {
      throw data;
    }

    return data;
  } catch (e) {
    throw new ResponseError(e);
  }
};

export default createAPI;
